// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

// Accordion
function initAccordion() {
  $('.item-title').click(function () {
    $(this).parent().toggleClass('is-active');
  });
}

// Burger Menu
function initHamburger() {
  const activeClass = 'active';
  const $button = $('.burger-button');
  const $navContainer = $('.header-nav');
  const $navItems = $('.header-link');

  function toggleActiveClass() {
    [$button, $navContainer].forEach((item) => item.toggleClass(activeClass));
  }

  $button.click(toggleActiveClass);
  $navItems.click(toggleActiveClass);
}

// Check if element entered viewport
function isInViewport(el) {
  var rect = el.getBoundingClientRect();

  return rect.top < window.innerHeight && rect.bottom >= 0;
}


var canvas,
  stage,
  exportRoot,
  anim_container,
  dom_overlay_container,
  fnStartAnimation;

function initHeroDots() {
  canvas = document.getElementById('canvas-bg');
  anim_container = document.getElementById('animation_container');
  dom_overlay_container = document.getElementById('dom_overlay_container');
  var comp = AdobeAn.getComposition('9441FA25C65B1B4387049F2F48B9C763');

  var lib = comp.getLibrary();
  handleComplete({}, comp);
}
function handleComplete(evt, comp) {
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  var ss = comp.getSpriteSheet();
  exportRoot = new lib.bgdesktop();
  stage = new lib.Stage(canvas);
  //Registers the "tick" event listener.
  fnStartAnimation = function () {
    stage.addChild(exportRoot);
    createjs.Ticker.framerate = lib.properties.fps;
    createjs.Ticker.addEventListener('tick', stage);
  };
  //Code to support hidpi screens and responsive scaling.
  AdobeAn.makeResponsive(false, 'both', false, 1, [
    canvas,
    anim_container,
    dom_overlay_container,
  ]);
  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
}

$(document).ready(function () {
  initAccordion();
  initHamburger();
  initAnchorScrolling();
  initHeroDots();
});
